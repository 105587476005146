import React from "react";
import Layout from "../../layouts/index";
import DirectorIntroductionImg from "../../images/director-introduction.jpg";

const style = {
  box: {
    marginBottom: 40,
  },
  item: {
    marginBottom: 20,
  },
};

const AboutUsContent = () => (
  <div className="container sub-content">
    <h1>当院について</h1>
    <div style={style.box}>
      <p>当院は1985年に開院しました。</p>
      <p>当院が追求するのは「安心」。</p>
      <p>
        充実の設備も、料金も、利便性も、丁寧に検査するのも、スタッフを大事にするのも、スタッフの教育に力を入れるのも、全て「安心」のため。
      </p>
      <p>
        当サイト以外では何も宣伝していないにもかかわらず、年間7000人以上受診していただき、契約企業様が900社を超えているのも、皆様にそういった姿勢を温かくご評価いただいてのことかと存じます。
      </p>
      <p>
        これからも、よりよい健康診断・人間ドックを提供できるよう、業務改善・システム開発に力を入れてまいります。
      </p>
      <p>
        何かご質問、お困りのことなどありましたら、お気軽にお問い合わせください。
      </p>
    </div>
    <div>
      <div>
        <hr />
        <h2>当院の健康診断・人間ドックの特徴</h2>
        <div style={style.box}>
          <p>　</p>
          <h4>高い専門性・充実の設備</h4>
          <ul>
            <li>大学病院級の検査機器をご用意</li>
            <li>胃カメラを施行するのは全員日本内視鏡学会認定の専門医</li>
            <li>大学病院の呼吸器内科医師も在籍</li>
          </ul>
          <h4>アフターフォローの充実</h4>
          <ul>
            <li>何か異常が見つかった場合、保険で精密検査や治療が受けられる</li>
            <li>
              内科外来、呼吸器外来、甲状腺外来をご用意。幅広い疾患に対応可能
            </li>
          </ul>
          <h4>高い利便性</h4>
          <ul>
            <li>
              時間がない方のために、
              <a href="/blogs/2012-7-16_2/">特急オプション</a>
              をご用意
            </li>
            <li>
              夜でも健康診断・ドックが受けられる（胃カメラを含まないコースのみ）
            </li>
            <li>渋谷駅B2出口からまっすぐ歩いて2分</li>
          </ul>
          <h4>超音波専門女性技師によるエコー検査</h4>
          <ul>
            <li>超音波検査は専門の女性技師が担当</li>
            <li>超音波検査機器は大学病院級</li>
            <li>検査時間をたっぷり確保し、丁寧に検査します</li>
            <li>
              腹部エコー、乳腺エコーのほか、頚動脈エコー、心エコー、甲状腺エコーも施行可能
            </li>
          </ul>
          <h4>安心の料金</h4>
        </div>
        <div>
          <hr />
          <h3>健康診断・ドックの受付時間</h3>
          <div style={style.box}>
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th className="text-center">時間帯</th>
                  <th className="text-center" style={{ color: "#d46464" }}>
                    日・祝
                  </th>
                  <th className="text-center">月</th>
                  <th className="text-center">火</th>
                  <th className="text-center">水</th>
                  <th className="text-center">木</th>
                  <th className="text-center">金</th>
                  <th className="text-center">土</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>9:00～12:00</td>
                  <td>〇</td>
                  <td>〇</td>
                  <td>〇</td>
                  <td>〇</td>
                  <td>〇</td>
                  <td>〇</td>
                  <td>〇</td>
                </tr>
                <tr>
                  <td>15:00～19:00</td>
                  <td>※1</td>
                  <td>〇</td>
                  <td>〇</td>
                  <td>〇</td>
                  <td>※2</td>
                  <td>〇</td>
                  <td>※1</td>
                </tr>
              </tbody>
            </table>
            <p>※1 土・日・祝の最終受付は16:00まで</p>
            <p>※2 木の最終受付は16:30まで</p>
          </div>
          <hr />
          <h3>設備</h3>
          <div id="facility" style={style.box}>
            <ul>
              <li>男女別お手洗い</li>
              <li>無料Wi-Fi(Free Spot)</li>
              <li>ウォーターサーバー</li>
            </ul>
          </div>
          <hr />
          <h3>アクセス</h3>
          <div id="access" style={style.box}>
            <iframe
              style={{ width: "100%", height: 400 }}
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.co.jp/maps?f=q&amp;source=s_q&amp;hl=ja&amp;geocode=&amp;q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%8B%E8%B0%B7%E5%8C%BA%E6%B8%8B%E8%B0%B71-24-6&amp;sll=36.5626,136.362305&amp;sspn=47.929672,79.013672&amp;brcurrent=3,0x60188ca7708bff2d:0xd910777563a5f6d9,0&amp;ie=UTF8&amp;hq=&amp;hnear=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%8B%E8%B0%B7%E5%8C%BA%E6%B8%8B%E8%B0%B7%EF%BC%91%E4%B8%81%E7%9B%AE%EF%BC%92%EF%BC%94%E2%88%92%EF%BC%96&amp;ll=35.659842,139.701827&amp;spn=0.00523,0.005364&amp;z=16&amp;iwloc=A&amp;output=embed"
              scrolling="no"
              frameBorder="0"
            />
            <div>
              <a
                href="http://www.google.co.jp/maps?f=q&amp;source=embed&amp;hl=ja&amp;geocode=&amp;q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%8B%E8%B0%B7%E5%8C%BA%E6%B8%8B%E8%B0%B71-24-6&amp;sll=36.5626,136.362305&amp;sspn=47.929672,79.013672&amp;brcurrent=3,0x60188ca7708bff2d:0xd910777563a5f6d9,0&amp;ie=UTF8&amp;hq=&amp;hnear=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%8B%E8%B0%B7%E5%8C%BA%E6%B8%8B%E8%B0%B7%EF%BC%91%E4%B8%81%E7%9B%AE%EF%BC%92%EF%BC%94%E2%88%92%EF%BC%96&amp;ll=35.659842,139.701827&amp;spn=0.00523,0.005364&amp;z=16&amp;iwloc=A"
                target="_blank"
              >
                大きな地図で見る
              </a>
            </div>
            <div>
              <span>JR山手線・埼京線</span>
              <ul className="list-unstyled">
                <li>渋谷駅宮益坂方面出口より　徒歩2分</li>
                <li>地下鉄半蔵門線・田園都市線</li>
                <li>渋谷駅B2出口より　徒歩2分</li>
              </ul>
            </div>
          </div>
          <hr />
          <div style={style.box}>
            <div>
              <h3>住所・連絡先</h3>
            </div>
            <ul className="list-unstyled">
              <li>〒150-0002　東京都渋谷区渋谷1-24-6 マトリクス・ツービル9F</li>
              <li>TEL：03-6419-7056</li>
              <li>FAX：03-3400-3039</li>
              <li>
                <a href="mailto:info@hirahata-clinic.or.jp">
                  info@hirahata-clinic.or.jp
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div>
          <h2>スタッフ紹介</h2>
        </div>
        <div className="row" style={style.box}>
          <div className="col-sm-4">
            <img
              className="mx-auto d-block rounded-circle"
              src={DirectorIntroductionImg}
              alt="院長"
            />
          </div>
          <div className="col-sm-8">
            <h3>平畑　光一　院長</h3>
            <p>
              出身は東京都大田区。山形大学医学部卒業。東邦大学大橋病院第３内科で一般内科、循環器内科を研修。国立病院機構東京病院にて呼吸器内科研修。その後東邦大学大橋病院消化器内科で大腸カメラ挿入時の疼痛、胃酸逆流に伴う症状などについて研究。胃腸疾患のほか、膵炎など、消化器全般の診療にたずさわった。
              <br />
              2008年 7月より
              <a href="http://hirahata-clinic.or.jp" target="_blank">
                渋谷ヒラハタクリニック
              </a>
              院長。
            </p>
            <ul className="list-unstyled">
              <li>日本消化器内視鏡学会専門医</li>
              <li>日本内科学会認定医</li>
              <li>日本医師会認定産業医</li>
              <li>旧通産省認定　第一種情報処理技術者</li>
              <li>LPIC Level 1取得</li>
            </ul>
            <p>担当：健康診断、人間ドック、内科、胃カメラ、大腸カメラ</p>
            <p>
              <a href="http://hirahata.com/" target="_blank">
                ブログ
              </a>
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div style={style.box}>
        <div>
          <h2>メディア紹介</h2>
        </div>
        <div className="row" style={{ marginLeft: 20 }}>
          <div className="col-sm-12" style={style.item}>
            <h4>TBSテレビ「健康カプセル！ゲンキの時間」取材協力</h4>
            <div>
              2013/8/18放送　TBSテレビ「健康カプセル！ゲンキの時間」　「人間ドック」の回の取材に協力いたしました。（クレジットなどは出ませんでした）
            </div>
          </div>
          <div className="col-sm-12" style={style.item}>
            <h4>日本テレビ「所さんの目がテン！」取材協力、医師出演</h4>
            <div>
              2011年1月29日放送の、日本テレビ「所さんの目がテン！」取材協力、医師出演
            </div>
          </div>
          <div className="col-sm-12" style={style.item}>
            <h4>ＢＳフジ「平成教育学院☆放課後」</h4>
            <div>2010年3月6日、3月20日放送</div>
          </div>
          <div className="col-sm-12" style={style.item}>
            <h4>朝日放送「最終警告！たけしの本当は怖い家庭の医学」取材協力</h4>
            <div>
              朝日放送「最終警告！たけしの本当は怖い家庭の医学」取材協力
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AboutUs = () => (
  <Layout>
    <AboutUsContent />
  </Layout>
);

export default AboutUs;
